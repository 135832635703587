// @hero
section.hero_module {
    .container {
        margin-bottom: 70px;
    }
    .video_modal {
        position: relative;
        margin-bottom: -70px;
        &.has-comment {
            // margin-bottom: 93px;
        }
        @include media-breakpoint-down(xl) {
            // margin-bottom: 0;
        }
        .video_modal-image {
            box-shadow: 0 62px 48px -47px rgba(5, 36, 219, 0.3);
        }
        a {
            appearance: none;
            .play-button {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                @include on-action() {
                }
                @include media-breakpoint-down(sm) {
                    width: 56px;
                    height: 56px;
                }
            }
        }
        .video-comment {
            position: absolute;
            display: flex;
            align-items: center;
            left: 55px;
            bottom: -90px;
            .comment-image-circle {
                border-radius: 25px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                &:before {
                    content: "";
                    display: block;
                    background-color: $primary;
                    width: 120px;
                    height: 120px;
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    border-radius: 60px;
                }
                img {
                    position: relative;
                    z-index: 200;
                    width: 114px;
                    height: 114px;
                }
            }
            p {
                font-size: 14px;
                margin-top: 2em;
                margin-left: 1em;
                margin-bottom: 0;
            }
        }
    }
}
