section.card_module {
    .cards {
        &.only_testimonals {
            &:not(.align-top) {
                padding-top: 90px;
            }

            .col-lg-4:not(.align-top) {
                &:nth-of-type(2) {
                    position: relative;
                    top: -90px;
                }
            }

            @include media-breakpoint-down(lg) {
                padding-top: 0;
                .col-lg-4 {
                    &:nth-of-type(2) {
                        position: relative;
                        top: 0px;
                    }
                }
            }
        }
        .card.testimony {
            position: relative;
            height: 100%;
            justify-content: center;
        }
    }

    &.bg-white {
        .cards {
            .card.content {
                box-shadow: 0 23px 35px -23px rgba(5,36,219,0.45);
            }
        }
    }
}
