// @pricing
section.pricing_module {
    .left-content {
        padding-right: 60px;

        .btn-container {
            width: fit-content;
            margin-top: 50px;

            .btn-below-link {
                font-size: 14px;
                color: #FFFFFF;
                letter-spacing: -0.42px;
                text-align: center;
                margin: 0 auto;
                width: fit-content;
            }
        }
    }
    .plans {
        .plan-card {
            background: #FFFFFF;
            border-radius: 14px;
            box-sizing: border-box;
            max-width: 48%;

            @media screen and (max-width: 991px) {
                max-width: calc(100% - 40px);
                margin: 20px;
            }
    
            .plan-title {
                font-weight: bold;
                margin: 0 0 20px 0;
                font-size: 20px;
                color: #4A4A4A;
                letter-spacing: -0.6px;
                text-align: center;
            }

            .plan-description {
                font-weight: 400;
                font-size: 14px;
                color: #535353;
                letter-spacing: -0.42px;
                text-align: center;
            }

            .pricing-info {
                .pricing-amount {
                    font-weight: bold;
                    font-size: 50px;
                    color: #011772;
                    letter-spacing: -1.5px;
                    text-align: center;
                    line-height: 38px;
                    margin: 0 0 10px 0;
                }

                .pricing-unit {
                    font-weight: 400;
                    font-size: 14px;
                    color: #A7A9AC;
                    letter-spacing: -0.42px;
                    text-align: center;
                    margin: 0 0 10px 0;
                }
            }

            .btn {
                width: 80%;
            }

            .checklist-before-text {
                font-weight: bold;
                font-size: 14px;
                color: #535353;
                letter-spacing: -0.42px;
                margin: 0 0 10px 0;
            }

            .checklist {
                list-style: none;
                margin: 0;
                padding: 0;
    
                .checklist-item {
                    padding: 8px 20px;
                    position: relative;
                    padding-left: 60px;
                    border: 0;
                    font-weight: 300;
                    font-size: 14px;
                    color: #535353;
                    letter-spacing: -0.42px;
    
                    &::after {
                        content: url('../images/checkmark.svg');
                        position: absolute;
                        top: 12px;
                        bottom: 0;
                        left: 20px;
                        height: 25px;
                        width: 25px;
                    }
                }
            }
        }
    }
}