//@.card styling
//@group _bootstrap
.card {
    &.with-icon {
        max-width: 200px;
        margin: 0 auto;
        .card-body {
            position: relative;
            padding: rem-val(50) rem-val(30) rem-val(25) rem-val(30) !important;
            
            .icon-container {
                padding: 10px;
                background: #FFFFFF;
                border: 1px solid #979797;
                box-shadow: 0 9px 7px -5px rgba(5,36,219,0.35);
                border-radius: 8px;
                display: block;
                position: absolute;
                top: -25px;
                left: 0;
                right: 0;
                width: 50px;
                height: 50px;
                .icon {
    
                }
            }
        }
    }

    &.testimony {
        padding: rem-val(40);
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
        z-index: 100;
        @include media-breakpoint-down(sm) {
            padding: 1.5em;
            margin: 1em auto;
        }
        .quote {
            margin-bottom: 1em;
        }
        .author-wrapper {
            margin-top: auto;
            justify-content: flex-start;
            position: relative;
            .author-image-circle {
                border-radius: 25px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                width: 48px;
                height: 48px;
                &:before {
                    content: "";
                    display: block;
                    background-color: $primary;
                    width: 52px;
                    height: 52px;
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    border-radius: 25px;
                }
                .author-image {
                    img {
                        position: relative;
                        z-index: 200;
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            .author-details {
                margin-left: 1em;
                margin-top: 0.25rem;
                p {
                    margin-bottom: 0;
                    font-size: rem-val(14);
                    font-weight: 400;
                    &.author-name {
                        margin: 0.2em 0;
                        font-weight: 700;
                    }
                }
                ul li {
                    font-weight: 400;
                }
            }
        }
        position: absolute;
        &.position-top {
            left: 50%;
            top: -5%;
            transform: translate(-50%, 0);
        }
        &.position-left {
            left: -20%;
            top: 20%;
            transform: translate(-50%, 0);
        }
        &.position-right {
            top: 10%;
            right: -35%;
            transform: translate(-50%, 0);
        }
        &.position-bottom {
            bottom: -25%;
            left: 50%;
            transform: translate(-50%, 0);
        }
        @include media-breakpoint-down(xl) {
            position: relative;
            &.right,
            &.bottom,
            &.left,
            &.top {
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;
                transform: unset;
            }
        }
    }
    &.content {
        border: 1px solid #979797;
        border-radius: 20px;
        // max-width: 800px;
        &.single {
            .card-body {
                padding: rem-val(75) rem-val(90);
                @include media-breakpoint-down(lg) {
                    padding: rem-val(37.5) rem-val(45);
                }
                @include media-breakpoint-down(sm) {
                    padding: rem-val(30) rem-val(15);
                }
            }
        }
        .card-body {
            padding: rem-val(50) rem-val(30);
            @include media-breakpoint-down(sm) {
                padding: rem-val(50) rem-val(25);
            }
        }
    }
}

.card-col {
    width: fit-content;
    margin-bottom: 50px !important;
}
