*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
}

body {
  &.homepage {
    main {
      section.hero_module {
        @include media-breakpoint-down(md) {
          padding-top: 150px !important;
        }
      }
    }
  }
}

html {
  scroll-padding-top: 200px;
  &.scrolling-disabled {
    height: 100vh;
    overflow: hidden;

    body {
      // position: relative;
      // overflow: hidden;
      // height: 100vh;
    }
  }
}

#main {
  padding-bottom: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.blue-span {
    span {
      color: $primary;
    }
  }
}

h1, .h1 {
  letter-spacing: -1.15px;
  font-weight: bold;
}
h2, .h2 {
  letter-spacing: -1.05px;
  font-weight: bold;
}
h3, .h3 {
  letter-spacing: -0.75x;
  font-weight: bold;
}
h4, .h4, h5, .h5, h6, .h6, p, .p, li, ol, a, button {
  letter-spacing: -0.6px;
}

p {
  font-weight: 400;
}

a {
}
a,
button {
  &:hover,
  &:active {
    outline: none !important;
  }
  &:focus, &.btn:focus {
    outline-offset: 5px;
    outline-color: $primary!important;
    outline: solid;
  }
  &.btn-primary:focus {
    outline-color: $secondary!important;
  }
}

[type=button] {
  appearance: none;
}

pre,      
code {
  font-size: 12px;
}

iframe,
img {
  max-width: 100%;
  height: auto;
  &.aligncenter {
    margin: 0 auto;
    display: block;
  }
}

ul.no-bullet-points {
  list-style-type: none;
  li {
    margin: rem-val(5) 0;
  }
}

ul {
  &:not(.checklist) {
    li {
      margin-bottom: 20px;
    }
  }
  li {
    ul {
      max-width: unset;
    }
  }
}

ul.blue-checkmark {
  position: relative;
  list-style-type: none;
  padding-left: rem-val(50);
  max-width: fit-content;
  margin: 0.25em auto 0;
  li {
    margin-top: 4px;
    
    padding-bottom: rem-val(30);
    position: relative;
    &:before {
      content: "";
      background-image: url("/assets/images/blue-checkmark.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 37px;
      height: 34px;
      position: absolute;
      left: -50px;
      top: -4px;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    ul {
      list-style-type: disc;
      max-width: unset;
      li {
        padding-bottom: 0px;
        &:before {
          content: none;
        }
      }
    }
  }
}

/* Reset heading sizes since bootstrap is ignoring our config and pulling the size from reboot.scss */
h1 {
  font-size: 1.25rem * 3;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.25rem * 1.75;
  margin-bottom: 30px;
}

h3, h4 {
  font-size: 1.25rem * 1.25;
  margin-bottom: 30px;
}

h5, h6 {
  font-size: 1.25rem;
  margin-bottom: 30px;
}