section.faq_module {
    .accordion {
        .accordion-item {
            border-left: none;
            border-right: none;
            border-radius: unset;
            padding: rem-val(25) rem-val(45) rem-val(25) rem-val(45);
            @include media-breakpoint-down(md) {
                padding: rem-val(25) rem-val(5);
            }

            .accordion-header {
                border: unset;
                button {
                    background-color: transparent;
                    padding: 0;
                    color: $primary;
                    border: unset;
                    box-shadow: unset;
                    display: flex;
                    justify-content: space-between;
                    &:focus {
                        background-color: transparent;
                        box-shadow: none;
                        border-color: unset;
                    }
                    &:after {
                        margin-left: 30px;
                        // padding-left: 10px;
                    }
                }
            }
            .accordion-body {
                padding: 1em 0 0 0;
                p {
                    font-weight: 300;
                    &:last-of-type {
                        margin-bottom: 1rem;
                    }
                }
                li {
                    font-weight: 300;
                    b, strong {
                        font-weight: 400;
                    }
                    span {
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
