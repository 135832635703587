// @logos checklist
section.logos_checklist_module {
    .logos {
        gap: 15px;
        .logo {
            border: 1px solid #979797;
            box-shadow: 0 23px 35px -23px rgba(5,36,219,0.45);
            border-radius: 20px;
            background: #ffffff;
            padding: 15px;

            img {
                width: 55px;
                height: 55px;
            }
        }
    }
    .btn-container {
        width: fit-content;
        margin-top: 50px;

        .btn-below-link, .btn-below-link:visited {
            font-size: 14px;
            color: #FFFFFF;
            letter-spacing: -0.42px;
            text-align: center;
            margin: 0 auto;
            width: fit-content;
        }
    }
    .checklist-container {
        background: #FFFFFF;
        border-radius: 14px;

        .checklist-title {
            font-size: 20px;
            font-weight: bold;
            color: #4A4A4A;
            letter-spacing: -0.6px;
            text-align: right;
            margin: 0 0 20px 0;
        }
        .checklist {
            list-style: none;
            margin: 0;
            padding: 0;

            .checklist-item {
                padding: 12px 20px;
                border-bottom: 1px solid #979797;
                position: relative;
                font-weight: bold;
                font-size: 20px;
                color: #4A4A4A;
                letter-spacing: -0.6px;
                padding-right: 60px;

                &:first-of-type {
                    border-top: 1px solid #979797;
                }

                &::after {
                    content: url('../images/checkmark.svg');
                    position: absolute;
                    top: 12px;
                    bottom: 0;
                    right: 20px;
                    height: 25px;
                    width: 25px;
                }
            }
        }
    }
}