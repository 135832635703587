// @.modal - ref [v4-alpha.getbootsrap.com/components#modal]
.modal {
	background-color: rgba($secondary, .5);
	&.video {
		.modal-dialog {
			max-width: 60vw;
			margin: 100px auto 0;
			@include media-breakpoint-down(lg) {
				max-width: 90vw;
			}
			@include media-breakpoint-down(md) {
				max-width: 80vw;
			}
			.modal-content {
				position: relative;
				[class*="modal"] {
				}
				.video-wrapper {
					position: relative;
					padding-bottom: 56.25%; /* 16:9 */
					padding-top: 25px;
					height: 0;
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
	&.form {
		.modal-dialog {
			max-width: 700px;
			margin: 125px auto 0;
			@include media-breakpoint-down(lg) {
				max-width: 90vw;
			}
			@include media-breakpoint-down(md) {
				max-width: 95vw;
				margin: 175px auto 0;
			}
			.modal-content {
				@include media-breakpoint-down(md) {
					.btn-close {
						right: 0;
						top: -30px;
					}
				}
				.modal-body {
					padding: rem-val(60) rem-val(90);
					border-radius: 25px;
					@include media-breakpoint-down(md) {
						padding: rem-val(30);
					}
					.form-wrapper {
						.gform_wrapper {
							padding: 0;
							.gform_heading {
								text-align: center;
								h2 {
									color: $secondary;
								}
								.gform_required_legend {
									margin-top: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
	.modal-dialog {
		.modal-content {
			position: relative;
			[class*="modal"] {
			}
			.modal-body {
				padding: 0;
			}
			.btn-close {
				position: absolute;
				right: -30px;
				top: -25px;
				color: $white;
				background-color: $white;
				opacity: 0.7;
				@include on-action() {
				}
			}
		}
	}
}
