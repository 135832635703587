//Custom Button Styling

.btn {
    width: auto;
}

.button-partial {
    text-align: center;
    p {
        margin-bottom: rem-val(15);
        font-size: rem-val(14);
    }

    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
    }
}

.sxs-video-play-button {
    img {
        position: relative;
        filter: drop-shadow(0 0 0.25rem $primary);
        transition: margin-top 0.3s ease-in-out, filter 0.3s ease-in-out;

    }
    &:active {
        img {
            margin-top: -2px;
        }
    }
    @include on-action() {
        img {
            filter: drop-shadow(0 0 0.75rem $primary);
        }
    }
}
