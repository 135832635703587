/// @group SingleTemplate
.single-post {
    #main {
        padding-bottom: 0;
    }
}
.post {

    h1, h2, h3, h4, h5, h6 {
        color: $primary;
    }

    &-content {
        &-wrapper {
            background-color: #f8f8f8;
            padding-bottom: 50px;
            .container {
                width: 800px;
                max-width: 100%;
            }
        }
    }

    &-author {
        border-top: 1px solid $body-color;
        border-bottom: 1px solid $body-color;

        strong {
            display: block;
            margin-bottom: $spacer;
        }

        &-image {
            img {
                border-radius: 50%;
            }
        }
    }

    &-read-next {
        padding: $spacer ($spacer * 2);
        background-color: #eee;
    }

    table {
        margin-bottom: $spacer * 2;
        td {
            border: 1px solid $body-color;
            padding: .5 * $spacer;
        }
    }
    .post-content-wrapper ul li {
        font-weight: 400;
    }
}

/// @group BreadCrumb
.breadcrumb {
    font-size: 1rem;
    &-item {
        text-decoration: none;
        &.active {
            color: $primary;
        }
        a {
            text-decoration: none;
            color: $body-color;
        }
    }
}