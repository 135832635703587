section.logo_train_module {
    .logos {
        max-width: 800px;
        width: 100%;

        .logo {
            flex: 0 0 25%;
            height: 144px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-down(md) {
                flex: 0 1 50%;
            }

            @include media-breakpoint-down(sm) {
                // flex: 0 0 100%;
            }
            a {
                img {
                    width: 100%;
                    max-width: 316px;
                    max-height: 180px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
