//@footer
footer {
    padding: rem-val(75) rem-val(120) rem-val(17);
    position: relative;

    @include media-breakpoint-down(md) {
        padding: rem-val(75) rem-val(60) rem-val(17);
    }
    @include media-breakpoint-down(sm) {
        padding: rem-val(75) rem-val(20) rem-val(17);
    }

    .raised-logo {
        position: absolute;
        top: -50px;
        left: 110px;
        @include media-breakpoint-down(md) {
            left: 15px;
        }
    }

    .footer_blurb {
        a {
            color: $white;
        }
    }

    .footer-links {
        @media screen and (max-width: 1680px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;
        .social-media {
            justify-content: center;
            margin-bottom: 1rem;
        }
    }
    #scroll-to-top {
        cursor: pointer;
        position: relative;
        &:active {
            top: -4px;
        }
    }

    .footer-nav {
        list-style: none;
        padding: 0;
        margin: 0 0 12px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        li {
            font-family: $font-family-lexend;
            font-weight: semibold;
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: -0.4px;
            margin: 0;
            padding: 0;
            a {
                color: #fff;
                margin: 0;
                padding: 0;

                &:hover {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}
