section.icon_banner_module {
    height: 100px;

    @include media-breakpoint-down(lg) {
        height: fit-content;
    }

    .container {
        height: 100%;

        .row {
            height: 100%;
        }
    }

    .icons {
        max-width: 900px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: nowrap;

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            padding: 10px 0;
        }

        .icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin: 10px;

            img {
                background: #FFFFFF;
                border: 1px solid #979797;
                box-shadow: 0 9px 7px -5px rgba(5,36,219,0.35);
                border-radius: 8px;
                height: 55px;
                width: 55px;
                padding: 8px;
                box-sizing: border-box;
            }

            p {
                font-family: $font-family-lexend;
                font-weight: bold;
                font-size: 20px;
                color: #4A4A4A;
                letter-spacing: -0.6px;
                max-width: 190px !important;
                margin: 0;
            }
        }
    }
}
