section.banner_module {
    .divider {
        width: 100%;
        height: 1px;
        background-color: #979797;
        &.top {
            position: relative;
            margin-top: 1.25em;
            margin-bottom: 3em;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &.bottom {
            margin-top: 3em;
            margin-bottom: 1.25em;
        }
    }
    .below-banner-content, .above-banner-content {
        font-size: 14px;
    }
    .banner-content ul li {
        font-weight: 400;
    }
}
