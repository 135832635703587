.gform_wrapper {
    max-width: 520px;
    margin: 0 auto;
    @include media-breakpoint-down(sm) {
        padding: 0 1em;
    }
    form {
        .gform_body {
            .gform_fields {
                .gfield {
                    text-align: left;
                    margin-top: 1.5em;
                    &:first-of-type:not(fieldset) {
                        margin-top: 0;
                    }
                    &.gfield_html {
                        margin-top: 0;
                    }
                    label, legend {
                        font-weight: 400;
                        margin-bottom: 0.5em;
                        font-size: 1em;
                        .gfield_required {
                            .gfield_required_asterisk {
                                color: $red;
                                font-size: 1em;
                            }
                        }
                    }
                    .ginput_container {
                        input,
                        textarea,
                        select {
                            background: #ffffff;
                            border: 1px solid #979797;
                            box-shadow: 0 23px 35px -23px rgba(5, 36, 219, 0.45);
                            border-radius: 6px;
                            height: 70px;
                            font-size: 1em;
                            padding: 0 1.5em;
                            color: $text;
                        }
                        textarea {
                            padding: 0.5em 1.5em;
                            height: 190px;
                        }
                        select {
                            appearance: none;
                        }
                        &.ginput_container_radio {
                            .gfield_radio {
                                .gchoice {
                                    margin: .25em 0;
                                    input {
                                        height: 40px;
                                    }
                                    label {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        &.ginput_container_select {
                            position: relative;
                            &:after {
                                content: "›";
                                width: 0px;
                                height: 0px;
                                font-size: 1.75em;
                                top: 30px;
                                right: 10px;
                                position: absolute;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
        .gform_footer {
            text-align: left;
            input[type="submit"] {
                margin-top: 2rem;
            }
        }
    }
}
.gform_confirmation_wrapper {
    margin-top: 60px;
}
