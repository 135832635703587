section.bw_logos_module {
    .logos {
        max-width: 1000px;
        // width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 25px;
        flex-wrap: wrap;

        .logo {
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #979797;
            box-shadow: 0 23px 35px -23px rgba(5,36,219,0.45);
            border-radius: 20px;
            width: 140px;

            @include media-breakpoint-down(md) {
                flex: 0 1 50%;
            }

            @include media-breakpoint-down(sm) {
                // flex: 0 0 100%;
            }
            a {
                filter: grayscale(1);
                img {
                    width: 100%;
                    max-width: 100px;
                    max-height: 1100px;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
