.animate {
    @include media-breakpoint-up(xl) {
        animation-duration: 1.25s;
        animation-iteration-count: 1;
        &.in {
            animation-timing-function: east-in;
            &.right {
                animation-name: slideInRight;
            }
            &.left {
                animation-name: slideInLeft;
            }
            &.bottom {
                animation-name: slideInBottom;
            }
            &.top {
                animation-name: slideInTop;
            }
        }
    }
}

@keyframes slideInRight {
    from {
        margin-right: -60%;
    }

    to {
        margin-right: 0%;
    }
}

@keyframes slideInLeft {
    from {
        margin-left: -60%;
    }

    to {
        margin-left: 0%;
    }
}

@keyframes slideInTop {
    from {
        margin-top: -60%;
    }

    to {
        margin-top: 0%;
    }
}

@keyframes slideInBottom {
    from {
        margin-bottom: -60%;
    }

    to {
        margin-bottom: 0%;
    }
}
