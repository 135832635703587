/// @group ArchiveTemplate
.blog-wrapper {
    .hero_module > .container {
        margin-bottom: 0 !important;
    }
}

/// Blog Posts
.blog-posts {
    margin-top: $spacer * 2;
    &__item {
        margin-bottom: $spacer * 2;
        align-self: stretch;
        .tease {
            height: 100%;
        }
    }
}

/// @group Tease
.tease {
    font-size: 1rem;

    &--post {
        overflow: hidden;

        background-color: $white;
        box-shadow: $box-shadow;
        border-radius: $spacer;

        transition: all .3s;

        &:hover {
            box-shadow: $box-shadow-lg;
        }

        .tease-img-top {
            display: block;
            border-radius: $spacer;
            overflow: hidden;
        }

        a {
            text-decoration: none;
        }
    }

    &-body {
        padding: $spacer ($spacer * 2) ($spacer * 2);
    }

    &-meta {
        font-size: .85em;
    }

    &-title {
        font-size: 1.75em;
        margin: $spacer 0;
    }

    &-content {}

    .read-more {
        font-size: 1.25em;

        margin-top: $spacer;
        display: block;
        transition: color 250ms ease-in;

        position: relative;

        &:after {
            content: "";
            display: block;
            border-bottom: solid 4px $primary;

            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            width: 50px;

            transition: all 250ms ease-in-out;
        }

        &:hover {
            &:after {
                width: 102px;
            }
        }
    }
}

/// @group Nav
.nav {
    &--categories {
        margin-bottom: $spacer;
        .nav-item {
            border-top: 1px solid $body-color;
            border-bottom: 1px solid $body-color;
        }
        .nav-link {
            color: $body-color;
            
            padding: $spacer ($spacer * 3);
            &:hover,
            &.active {
                color: $primary;
            }
        }
    }
}

/// @group Pagination
.pagination {
    &-wrapper { // scope to blog
        .pagination {
            .page-link {
                background-color: transparent;
                border: none;
                border-radius: 0;
                color: $body-color;
                box-shadow: none !important;
                &:hover {
                    text-decoration: underline;
                }
            }
            .active .page-link,
            .page-link:hover {
                color: $primary;
            }
        }
    }
}