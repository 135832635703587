nav.navbar {
	box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.15);
	padding: 0 rem-val(100);
	position: sticky;
	top: 0;

	z-index: 1054;
	height: 80px;
	transition: background-color 0.25s ease-in;
	&.homepage {
		// background-color: transparent !important;
		// box-shadow: none;
		// .logo {
		// 	&.blue {
		// 		display: none;
		// 	}
		// 	&.transparent {
		// 		display: block;
		// 	}
		// }
		// .collapse:not(.show) {
		// 	.navbar-nav {
		// 		.nav-item {
		// 			.nav-link {
		// 				color: $white;
		// 			}
		// 			&.btn-primary {
		// 				.nav-link:focus {
		// 					outline-color: $white!important;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		// .navbar-toggler {
		// 	appearance: none;
		// 	.hamburger {
		// 		span:not(.hamburger-box) {
		// 			background-color: $white;
		// 			&:after,
		// 			&:before {
		// 				background-color: $white;
		// 			}
		// 		}
		// 	}
		// }
	}

	.logo {
		&.transparent {
			display: none;
		}
		&.blue {
			display: block;
		}
	}

	.nav-item {
		font-size: 18px;
		margin: 0;
		&.btn {
			font-size: 18px;
		}
		.nav-link {
			font-size: 18px;
		}
	}

	@include media-breakpoint-down(xl) {
		padding: 0 2.5em;
	}
	@include media-breakpoint-down(lg) {
		height: auto;
		padding: 10px 0 0;
	}
	@include media-breakpoint-down(sm) {
		.navbar-brand {
			img {
				max-width: 75%;
			}
		}
	}

	.navbar-brand {
		margin: 0;
		padding: 10px 0;
		font-size: calc(1.28125rem + 0.375vw);
		@include media-breakpoint-down(lg) {
			padding-left: rem-val(50);
		}
		@include media-breakpoint-down(sm) {
			padding-left: 12px;
		}
	}

	.navbar-toggler {
		appearance: none;
		@include media-breakpoint-down(lg) {
			padding-right: rem-val(50);
		}
		@include media-breakpoint-down(sm) {
			padding-right: 5px;
		}
	}

	.collapse,
	.collapsing {
		height: 100%;
		// @nav ul
		@include media-breakpoint-down(lg) {
			height: auto;
			background-color: $white;
			box-shadow: 0 23px 35px -23px rgba(5, 36, 219, 0.45);
		}
		@include media-breakpoint-down(sm) {
			padding: 0!important;
			.navbar-nav {
				padding: 1em;
			}
		}
		.navbar-nav {
			height: 100%;
			@include media-breakpoint-down(lg) {
				height: auto;
			}
			// @nav li
			.nav-item {
				height: 100%;
				padding: 0 rem-val(25);
				display: flex;
				align-items: center;
				.nav-link {
					width: max-content;
					color: $dark;
					display: inline-block;
					margin: 0;
					transition: color 250ms ease-in;
					&:after {
						display: block;
						content: "";
						border-bottom: solid 4px $primary;
						position: relative;
						top: 5px;
						transform: scaleX(0);
						transition: transform 250ms ease-in-out;
					}
					&.show {
						svg {
							transform: rotate(-180deg);
							transition: transform 250ms ease-in-out;
						}
					}
				}
				.dropdown-menu {
					padding: 0;
					border-radius: 0;
					.dropdown-item {
						padding: 12px 20px;
						&.active {
							color: $primary;
							background-color: #F5F5F5;
						}
						
					}
					@include media-breakpoint-down(lg) {
						border: none;
						box-shadow: none;
						margin-top: 5px;
						.dropdown-item {
							text-align: center;
							&.active {
								background-color: transparent;
							}
						}
					}
				}

				&:hover {
					.nav-link {
						color: $primary;
						&:after {
							transform: scaleX(1);
						}
						svg {
							#caret-nav {
								fill: #0524DB !important;
							}
						}	
					}
				}

				&.btn {
					height: auto !important;
					margin-left: rem-val(25);
					padding: 0.5rem rem-val(25);
					.nav-link {
						padding: 0;
						color: $white;
						&:after {
							content: none;
						}
					}
				}

				// @nav li.active
				&.active:not(.btn) {
					.nav-link {
						color: $primary;
						text-decoration: underline;
						text-underline-offset: 8px;
						text-decoration-thickness: 4px;
					}
				}
				@include media-breakpoint-down(xl) {
					padding: 0 1em;
					.nav-link {
						padding: 0;
					}
				}
				@include media-breakpoint-down(lg) {
					padding: 1em;
					flex-direction: column;
					&.btn {
						margin-top: 1rem;
						margin-left: 0;
					}
				}
			}
		}
		@include media-breakpoint-down(lg) {
			padding: 1em;
		}
	}
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
	// padding: 15px 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}
.hamburger:hover {
	// opacity: 0.7;
	opacity: 1;
}
.hamburger.is-active:hover {
	// opacity: 0.7;
	opacity: 1;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: $secondary;
}

.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 40px;
	height: 4px;
	background-color: $secondary;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: -10px;
}
.hamburger-inner::after {
	bottom: -10px;
}
/*
* Squeeze
*/
.hamburger--squeeze .hamburger-inner {
	transition-duration: 0.075s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
	transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
	transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
	transform: rotate(45deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
	bottom: 0;
	transform: rotate(-90deg);
	transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
