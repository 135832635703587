section.locked_in_place_slider_module {
    position: relative;
    .above-slider {
        // margin-bottom: 300px;
    }
    section.text-slider {
        position: relative;
        height: 200px;
        width: 700px;
        margin: 0 auto;
        display: block;
        text-align: center;
        @include media-breakpoint-down(md) {
            display: none;
        }
        .glide {
            .glide__track {
                .glide__slides {
                    .glide__slide {
                        p {
                            max-width: 525px;
                            margin: 0 auto;
                            display: block;
                            font-size: rem-val(25);
                        }
                    }
                }
            }
            .glide__bullets {
                position: absolute;
                display: flex;
                flex-direction: column;
                top: -70%;
                right: -10%;
                .glide__bullet {
                    display: inline-block; /* add this */
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    border-style: solid;
                    border-width: 1px;
                    border-color: $secondary;
                    background-color: rgba(0, 0, 0, 0);
                    padding: 0;
                    margin: 3px 0;
                    transition: background-color 0.25s ease-in;
                    &--active {
                        background-color: $secondary;
                    }
                    &:hover {
                        background-color: $secondary;
                    }
                }
                @include media-breakpoint-down(xl) {
                    top: -20%;
                    right: 0%;
                }
                @include media-breakpoint-down(lg) {
                    position: relative;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 1rem;
                    top: 0;
                    right: 0;
                    .glide__bullet {
                        margin: 0px 3px;
                    }
                }
            }
        }

        .text-bullet-indicators {
            position: relative;
            margin-left: auto;
            width: 50px;
            margin-top: 90px;
            margin-right: 20%;
            display: flex;
            flex-direction: column;
            .bullet {
                display: inline-block; /* add this */
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border-style: solid;
                border-width: 1px;
                border-color: $text;
                background-color: rgba(0, 0, 0, 0);
                margin: 5px 0;
                transition: background-color 0.25s ease-in;

                &.active {
                    background-color: $text;
                }
            }
        }
    }
}

//text animation
