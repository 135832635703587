.gdpr {
    padding: 1rem 0;
    font-size: rem-val(13);

    background-color: $white;

    display: block;
    z-index: 1010;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    box-shadow: 0 -23px 35px -23px rgba(5, 36, 219, 0.45);

    .btn {
        // font-size: rem-val(10);
        padding: .5rem .5rem;
        &.btn-primary,
        &.btn-warning {
            // @include hover-underline($white, span);
        }
        &.btn-outline-primary {
            border-color: transparent;
            @include on-action() {
                color: $primary;
                background-color: transparent;
            }
            // @include hover-underline($primary, span)
        }
    }

    animation: gdpr .3s ease-in-out;

    @include media-breakpoint-down(md) {
        .d-flex {
            flex-direction: column;
        }
        .gdpr-actions {
            display: flex;
            width: 100%;
            margin-top: 1rem;
        }
    }
}
@keyframes gdpr {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
