// @form hero
section.form_hero_module {
    .container {
        margin-bottom: 70px;
    }
    .form-container {
        background: #FFFFFF;
        box-shadow: 0 42px 35px -23px rgba(5,36,219,0.35);
        border-radius: 20px;
        margin: 25px 0;

        .form {
            .gform_wrapper {
                form {
                    .gform_body {
                        .gform_fields {
                            .gfield {
                                margin-top: 0.5em;
                                .gfield_label {
                                    margin-bottom: 0;
                                    font-size: 14px;

                                    .gform_required {
                                        margin-left: 1px;
                                    }
                                }
                                .ginput_container {
                                    margin-top: 4px;
                                    input {
                                        margin-bottom: 0;
                                        padding: 5px 20px;
                                        height: 50px;
                                    }
                                }
                                .ginput_container_checkbox {
                                    .gfield_checkbox {
                                        .gchoice {
                                            .gfield-choice-input {
                                                margin: 0;
                                                padding: inherit;
                                                height: fit-content;
                                                min-height: 14px;
                                            }
                                            .gform-field-label {
                                                font-size: 14px;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .gform_footer {
                        input[type="submit"] {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
