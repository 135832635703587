section.side_by_side_module {
    overflow-x: clip;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .container-fluid {
        max-width: 2000px;

        .row {
            margin: 0;
        }
    }
    .image-wrapper {
        position: relative;
    }

    .content.horizontal {
        ul.blue-checkmark {
            left: 0;
        }
    }

    .side {
        padding: 2em;

        @include media-breakpoint-down(md) {
            padding: 1em;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        &.content {
            display: flex;
            flex-direction: column;
            p:last-of-type {
                margin-bottom: 0;
            }
            p + ul {
                margin: 2.5em 2em;
            }
            .content-container {
                ul {
                    margin-bottom: 0;
                    li {
                        font-weight: 400;
                    }
                }
            }
        }
        &.video {
            .video_modal {
                position: relative;
                &.has-comment {
                    margin-bottom: 93px;
                }

                .sxs-video-play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                a.sxs-video-circle-button {
                    position: absolute;
                    left: 55px;
                    bottom: -90px;
                    color: $text;
                    text-decoration: none;
                    .video-comment {
                        display: flex;
                        align-items: center;
                        .comment-image-circle {
                            border-radius: 60px;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 0 0 auto;
                            &:before {
                                content: "";
                                display: block;
                                background-color: $primary;
                                width: 120px;
                                height: 120px;
                                position: absolute;
                                top: -3px;
                                left: -3px;
                                border-radius: 60px;
                            }
                            img {
                                position: relative;
                                z-index: 200;
                                width: 114px;
                                height: 114px;
                            }
                        }
                        p {
                            font-size: 14px;
                            margin-top: 2em;
                            margin-left: 1em;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
