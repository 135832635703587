section.side_by_side_with_accordion_module {
    * {
        box-sizing: border-box;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .container-fluid {
        max-width: 2000px;

        &.flipped {
            @media(max-width: 991px) {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }

        .row {
            width: 100%;
            max-width: 100%;;
        }
    }
    .image-wrapper {
        position: relative;
    }

    .content.horizontal {
        ul.blue-checkmark {
            left: 0;
        }
    }

    .side {
        padding: 2em;
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
        .header {
            font-size: 35px;
            margin-bottom: 20px;
        }
        .subheader {
            font-size: 20px;
        }
        &.content {
            display: flex;
            flex-direction: column;
            p:last-of-type {
                margin-bottom: 0;
            }
            p + ul {
                margin: 2.5em 2em;
            }
            .accordion {
                .accordion-item {
                    border-left: 0;
                    border-right: 0;
                    .accordion-header {
                        .accordion-button {
                            &:not(.collapsed) {
                                background-color: transparent !important;
                                box-shadow: none !important;
                            }
                            p {
                                color: #4A4A4A;
                                font-weight: bold;
                                letter-spacing: -0.75px;
                                padding-right: 15px;

                            }
                            .icon-wrapper {
                                padding: 0 10px;
                                img {
                                    width: 25px;
                                    height: 25px;
                                }
                            }       
                        }
                    }
                }
            }
        }
        &.image {
            .images {
                // display: none;
                // &:first-child {
                //     display: block;
                // }
            }
        }
        &.video {
            .videos {
                display: none;
                &:first-child {
                    display: block;
                }
            }
        }
    }
    .accordion-body ul li {
        font-weight: 400;
    }
}
